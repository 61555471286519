import React, { memo, useCallback } from 'react';

import { IconChevronDown } from '@adc/parallax-icons';

import { IconButton, LegacyInput } from 'Components/utility';

type SelectorInputProps = {
  label: string;
  'aria-label': string;
  placeholder?: string;
  testID?: string;
  value?: string;
  onClick: () => void;
};

const SelectorInput = memo(({ onClick, testID, ...rest }: SelectorInputProps) => {
  const onEventStart = useCallback(() => {
    onClick();
  }, [onClick]);

  return (
    <LegacyInput
      nativeID={testID}
      editable={false}
      onTouchEnd={onEventStart}
      onFocus={onEventStart}
      InputRightElement={
        <IconButton
          marginRight="$2"
          style={{ cursor: 'pointer', position: 'absolute', right: 0 }}
          onPress={onEventStart}
        >
          <IconChevronDown
            testID={`${testID}-arrowDown-btn`}
            color="$interactive.tertiary.enabled.text"
          />
        </IconButton>
      }
      {...rest}
    />
  );
});

SelectorInput.displayName = 'SelectorInput';

export { SelectorInput };
