import React, { useCallback, useState } from 'react';

import { Text } from '@adc-polaris-component-library/component-library';
import { AxiosError } from 'axios';
import { Box, Stack } from 'native-base';

import { InsuletStatusCode } from 'Enums';

import { RichText } from 'Components/utility';

import { ModalProps, SHOW_MODAL } from 'Reducers/modal/types';

import { getDifferenceInMinutesFromNow } from 'Utilities/dateHelpers';
import i18n from 'Utilities/i18n';
import { logout } from 'Utilities/session';
import { store } from 'Utilities/store';

const useAuth = (redirect_uri?: string) => {
  const [isLocked, setIsLocked] = useState(false);
  const [lockoutMessage, setLockoutMessage] = useState('');
  const [lockoutSubmessage, setlockoutSubmessage] = useState('');

  const redirectOnError = useCallback(
    (statusCode: number) => {
      if (redirect_uri) {
        const separator = redirect_uri.includes('?') ? '&' : '?';

        window.location.replace(`${redirect_uri}${separator}errorcode=${statusCode}`);
      }
    },
    [redirect_uri]
  );

  const redirect = useCallback((redirectUrl: string) => {
    window.location.replace(redirectUrl);
  }, []);

  const handleLockoutError = (error: AxiosError) => {
    const attemptsResetAt = Number(error.response?.headers['x-attempts-reset-at']);

    showLockoutMessage(attemptsResetAt);
  };

  const showLockoutMessage = useCallback(
    (attemptsResetAt: number) => {
      const { time, difference } = getDifferenceInMinutesFromNow(attemptsResetAt);

      if (difference < 0) {
        return;
      }

      setIsLocked(true);

      setLockoutMessage(i18n.t<string>('Login.modals.signInErrors.accountLocked'));
      setlockoutSubmessage(
        i18n.t<string>('Login.modals.signInErrors.accountLockedPeriod', { time })
      );

      const lockoutInterval = setInterval(() => {
        const { time, difference } = getDifferenceInMinutesFromNow(attemptsResetAt);

        if (difference < 0) {
          clearInterval(lockoutInterval);
          setLockoutMessage('');
          setlockoutSubmessage('');
          setIsLocked(false);
        } else {
          setlockoutSubmessage(
            i18n.t<string>('Login.modals.signInErrors.accountLockedPeriod', { time })
          );
        }
      }, 1000);

      setTimeout(() => {
        redirectOnError(InsuletStatusCode.LOCKOUT_ERROR);
      }, 5000);
    },
    [redirectOnError]
  );

  const showLoginFailure = useCallback(
    (
      attemptsRemaining = -1,
      attemptsResetAfter?: number,
      attemptsResetAt?: number,
      onCloseAfterLockout?: () => void,
      onClose?: () => void
    ) => {
      const modalProps: ModalProps = {};

      modalProps.body = (
        <Stack space={4}>
          <Text
            color="text.100"
            fontWeight={'bodyBaseDefault'}
            fontFamily={'bodyBaseDefault'}
            fontSize="base"
            nativeID="Login.modals.signInErrors.loginFailure"
            mb={1}
          >
            {i18n.t<string>('Login.modals.signInErrors.loginFailure')}
          </Text>
          {attemptsRemaining >= 0 ? (
            <Box display="inline-block">
              <Text
                color="text.100"
                fontWeight={'bodyBaseDefault'}
                fontFamily={'bodyBaseDefault'}
                fontSize="base"
                nativeID="Login.modals.signInErrors.attemptsRemaining"
              >
                {i18n.t<string>('Login.modals.signInErrors.attemptsRemaining', {
                  count: attemptsRemaining,
                })}
              </Text>
              {attemptsResetAfter ? (
                <Text
                  color="text.100"
                  fontToken="bodyBaseMedium"
                  nativeID="Login.modals.signInErrors.accountWillBeLocked"
                >
                  {i18n.t<string>('Login.modals.signInErrors.accountWillBeLocked', {
                    time: attemptsResetAfter,
                    duration: attemptsResetAfter,
                  })}
                </Text>
              ) : null}
            </Box>
          ) : null}
        </Stack>
      );

      modalProps.onClose = () => {
        onClose && onClose();
      };

      if (attemptsResetAfter && attemptsResetAt) {
        modalProps.onClose = () => {
          showLockoutMessage(attemptsResetAt);
        };
      }

      store.dispatch({
        type: SHOW_MODAL,
        modalProps,
      });
    },
    [showLockoutMessage]
  );

  const handleLoginError = (
    error: AxiosError<ApiErrorData>,
    onCloseAfterLockout: () => void,
    onClose?: () => void
  ) => {
    if (error.response?.data.details === 'Authorization.isRequired') {
      logout();
      return;
    }

    if (error.response && error.response.headers['x-attempts-remaining']) {
      const attemptsRemaining = Number(error.response.headers['x-attempts-remaining']);
      const attemptsResetAt = error.response.headers['x-attempts-reset-at']
        ? Number(error.response.headers['x-attempts-reset-at'])
        : 0;
      const attemptsResetAfter = error.response.headers['x-attempts-reset-after']
        ? Number(error.response.headers['x-attempts-reset-after']) / 60
        : 0;

      showLoginFailure(
        attemptsRemaining,
        attemptsResetAfter,
        attemptsResetAt,
        onCloseAfterLockout,
        onClose
      );
      return;
    }

    showLoginFailure();
  };

  const handleCountryRegionError = useCallback(() => {
    redirectOnError(InsuletStatusCode.WRONG_REGION);
  }, [redirectOnError]);

  const handleForgotPasswordDone = useCallback(() => {
    redirectOnError(InsuletStatusCode.FORGOT_PASSWORD);
  }, [redirectOnError]);

  const handleSessionExpired = useCallback(() => {
    redirectOnError(InsuletStatusCode.INTERNAL_ERROR);
  }, [redirectOnError]);

  const showHcpError = useCallback(() => {
    store.dispatch({
      type: SHOW_MODAL,
      modalProps: {
        body: (
          <Stack space={4} nativeID="Global.modals.blockHCPUser.body">
            <RichText
              text={i18n.t<string>('Global.modals.blockHCPUser.body', {
                appName: i18n.t<string>('Global.appNames.libreView'),
              })}
            />
          </Stack>
        ),
      },
    });
  }, []);

  const showTokenExpirationError = useCallback(() => {
    store.dispatch({
      type: SHOW_MODAL,
      modalProps: {
        body: (
          <Stack space={4}>
            <RichText
              testID="Global.modals.linkNotValid.title"
              text={i18n.t<string>('Global.modals.linkNotValid.title')}
            />
            <Text
              color="text.100"
              fontWeight="bodyBaseDefault"
              fontFamily="bodyBaseDefault"
              fontSize="base"
              nativeID="Global.modals.linkNotValid.body"
              mt={1}
            >
              {i18n.t<string>('Global.modals.linkNotValid.body')}
            </Text>
          </Stack>
        ),
      },
    });
  }, []);

  const showForgotPasswordBlockedMessage = useCallback(() => {
    store.dispatch({
      type: SHOW_MODAL,
      modalProps: {
        body: (
          <Stack space={4}>
            <Text
              color="text.100"
              fontWeight={'bodyBaseDefault'}
              fontFamily={'bodyBaseDefault'}
              fontSize="base"
              nativeID="ForgotPassword.modals.forgotErrors.youReached"
              mb={1}
            >{`${i18n.t('ForgotPassword.modals.forgotErrors.youReached')}`}</Text>
            <Text
              color="text.100"
              fontWeight={'bodyBaseDefault'}
              fontFamily={'bodyBaseDefault'}
              fontSize="base"
              nativeID="ForgotPassword.modals.forgotErrors.consider"
              mb={1}
            >{`${i18n.t('ForgotPassword.modals.forgotErrors.consider')}`}</Text>
          </Stack>
        ),
      },
    });
  }, []);

  const showForgotPasswordConfirmatioMessage = useCallback(() => {
    store.dispatch({
      type: SHOW_MODAL,
      modalProps: {
        body: (
          <Stack space={4}>
            <Text
              color="text.100"
              fontWeight={'bodyBaseDefault'}
              fontFamily={'bodyBaseDefault'}
              fontSize="base"
              nativeID="ForgotPassword.modals.forgotErrors.sentInstructions"
              mb={1}
            >
              {i18n.t<string>('ForgotPassword.modals.forgotErrors.sentInstructions')}
            </Text>
            <Text
              color="text.100"
              fontWeight={'bodyBaseDefault'}
              fontFamily={'bodyBaseDefault'}
              fontSize="base"
              nativeID="ForgotPassword.modals.forgotErrors.notSeeingEmail"
              mb={1}
            >
              {i18n.t<string>('ForgotPassword.modals.forgotErrors.notSeeingEmail')}
            </Text>
          </Stack>
        ),
      },
    });
  }, []);

  return {
    handleLoginError,
    handleLockoutError,
    handleCountryRegionError,
    handleForgotPasswordDone,
    handleSessionExpired,
    showHcpError,
    isLocked,
    lockoutMessage,
    lockoutSubmessage,
    showTokenExpirationError,
    showForgotPasswordBlockedMessage,
    showForgotPasswordConfirmatioMessage,
    redirect,
  };
};

export default useAuth;
