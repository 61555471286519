import React, { useCallback, useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';

import { FormikHelpers, useFormik } from 'formik';
import * as Yup from 'yup';

import { RegisterProps } from 'Components/register/CreateNewUser';
import {
  Footer,
  Form,
  Header,
  Input,
  InputPassword,
  LogoActionBar,
  Main,
  PasswordInputValidator,
  ScrollContainer,
  Spinner,
} from 'Components/utility';
import { NavigationProps } from 'Components/wizard';

import { REGISTER } from 'Reducers/register/types';

import i18n from 'Utilities/i18n';
import log from 'Utilities/log';
import { store } from 'Utilities/store';

import { RootState } from 'src/reducers';

const mapStateToProps = ({ config: { validation } }: RootState) => {
  return {
    validation,
    leadingTrailingSpacesIdentity: validation?.identity.find(
      (identity) => identity.data.key === 'leadingTrailingSpaces'
    ),
  };
};

const connector = connect(mapStateToProps);

type Props = ConnectedProps<typeof connector> & NavigationProps<Register> & RegisterProps;

const RegisterAccountInfo: React.FC<Props> = ({
  back,
  values: currentValues,
  handleSubmitRegister,
  handleClickSignIn,
  loading,
  validation,
  leadingTrailingSpacesIdentity,
}) => {
  const [meetsPasswordRequirements, setMeetsPasswordRequirements] = useState(false);

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email(
        i18n.t<string>(
          'CreateAccountAccountInfo.content.createAccountAccountInfoForm.formField.email.errors.emailAddressValidEmail'
        )
      )
      .required(
        i18n.t<string>(
          'CreateAccountAccountInfo.content.createAccountAccountInfoForm.formField.email.errors.required'
        )
      ),
    password: leadingTrailingSpacesIdentity
      ? Yup.string().matches(
          new RegExp(leadingTrailingSpacesIdentity.data.regex),
          i18n.t<string>(
            `Login.content.signInForm.formField.password.helpText.${leadingTrailingSpacesIdentity.data.key}`
          )
        )
      : Yup.string(),
    confirmPassword: Yup.string()
      .oneOf(
        [Yup.ref('password')],
        i18n.t<string>(
          'CreateAccountAccountInfo.content.createAccountAccountInfoForm.formField.confirmPassword.errors.confirmPasswordMustMatch'
        )
      )
      .required(
        i18n.t<string>(
          'CreateAccountAccountInfo.content.createAccountAccountInfoForm.formField.confirmPassword.errors.required'
        )
      ),
  });

  const onSubmit = useCallback(
    async (values: Register, { setSubmitting }: FormikHelpers<Register>) => {
      try {
        setSubmitting(true);

        const register: Register = {
          ...currentValues,
          ...values,
          password: values.password.trim(),
        };

        store.dispatch({
          type: REGISTER,
          register,
        });
        await handleSubmitRegister(register);
      } catch {
        log.error('Registration error');
      } finally {
        setSubmitting(false);
      }
    },
    [currentValues, handleSubmitRegister]
  );

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldValue,
    isValid,
    errors,
    touched,
    values,
    isSubmitting,
  } = useFormik({
    initialValues: {
      ...currentValues,
    },
    validationSchema,
    onSubmit,
    validateOnBlur: true,
  });

  const handleOnBack = useCallback(() => {
    back({ ...values, password: '', confirmPassword: '' });
  }, [back, values]);

  return (
    <Main>
      <LogoActionBar
        actionButtonTestID="RegisterAccountInfo.action-go-back"
        titleTestID="RegisterAccountInfo.actionTitle"
        title={i18n.t<string>('CreateAccountAccountInfo.title')}
        onBack={handleOnBack}
      />
      {loading && <Spinner />}
      <ScrollContainer>
        <Header
          titleTestID="RegisterAccountInfo.title"
          subtitleTestID="RegisterAccountInfo.subtitle"
          title={i18n.t<string>('CreateAccountAccountInfo.title')}
          subtitle={i18n.t<string>('Login.subtitle', {
            appName: i18n.t<string>('Global.appNames.libreView'),
          })}
        />
        <Form>
          <Input
            aria-label={i18n.t<string>(
              'CreateAccountAccountInfo.content.createAccountAccountInfoForm.formField.email.label'
            )}
            placeholder={i18n.t<string>(
              'CreateAccountAccountInfo.content.createAccountAccountInfoForm.formField.email.placeholder'
            )}
            label={i18n.t<string>(
              'CreateAccountAccountInfo.content.createAccountAccountInfoForm.formField.email.label'
            )}
            errorMessage={errors.email}
            isInvalid={errors.email && touched.email ? true : false}
            testID="email"
            value={values.email}
            onChangeText={handleChange('email')}
            onBlur={handleBlur('email')}
            inputMode="email"
          />
          <PasswordInputValidator
            placeholder={i18n.t<string>(
              'CreateAccountAccountInfo.content.createAccountAccountInfoForm.formField.password.placeholder'
            )}
            label={i18n.t<string>(
              'CreateAccountAccountInfo.content.createAccountAccountInfoForm.formField.password.label'
            )}
            errors={errors}
            touched={touched}
            setFieldValue={setFieldValue}
            handleBlur={handleBlur('password')}
            onChangeText={handleChange('password')}
            password={values.password}
            onValidate={setMeetsPasswordRequirements}
            identities={validation?.identity}
            testID="password"
          />
          <InputPassword
            placeholder={i18n.t<string>(
              'CreateAccountAccountInfo.content.createAccountAccountInfoForm.formField.confirmPassword.placeholder'
            )}
            label={i18n.t<string>(
              'CreateAccountAccountInfo.content.createAccountAccountInfoForm.formField.confirmPassword.label'
            )}
            isInvalid={errors.confirmPassword && touched.confirmPassword ? true : false}
            errorMessage={errors.confirmPassword}
            testID="confirmPassword"
            setFieldValue={setFieldValue}
            onBlur={handleBlur('confirmPassword')}
            onChangeText={handleChange('confirmPassword')}
            value={values.confirmPassword}
          />
        </Form>
      </ScrollContainer>
      <Footer
        buttonText={i18n.t<string>(
          'CreateAccountAccountInfo.content.createAccountAccountInfoForm.links.next.label'
        )}
        buttonTestID="RegisterAccountInfo.nextButton"
        buttonLinkTitle={i18n.t<string>(
          'CreateAccountAccountInfo.content.footerSignIn.primaryText'
        )}
        buttonLinkTitleTestID="RegisterAccountInfo.alreadyHaveAccount"
        buttonLinkText={i18n.t<string>(
          'CreateAccountAccountInfo.content.footerSignIn.links.signIn.label'
        )}
        buttonLinkTestID="RegisterAccountInfo.signIn"
        onButtonSubmit={handleSubmit}
        onButtonLinkClick={handleClickSignIn}
        isButtonDisabled={
          !values.email ||
          !values.password ||
          !values.confirmPassword ||
          !isValid ||
          isSubmitting ||
          !meetsPasswordRequirements
        }
      />
    </Main>
  );
};

export default connector(RegisterAccountInfo);
